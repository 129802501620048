<template>
  <div class="companyTasksKanban">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem active> Tasks </CBreadcrumbItem>
    </teleport>

    <CCard>
      <CCardBody>
        <CRow class="justify-content-md-between g-3 mb-3">
          <CCol class="GCFilterMaxWidth col-12 col-md-9 class-lg-10">
            <GCFilter
              component="TaskBoard"
              @search="(val) => fetchData(val, columnType)"
            />
          </CCol>
          <CCol class="col-md-auto col-12 mt-4">
            <router-link
              :to="{ name: 'CompanyTasks', params: { id: $route.params.id } }"
              class="link-tb"
            >
              <CIcon name="cil-list" style="margin-right: 5px" />
              Table
            </router-link>
          </CCol>
        </CRow>
        <KanbanBoard :data="tasks" />
      </CCardBody>
    </CCard>
  </div>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
